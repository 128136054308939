import React, { useState, useEffect } from 'react';
import { IoMdClose } from 'react-icons/io'; 

type PopupBannerProps = {
  onClose: () => void;
};

const PopupBanner: React.FC<PopupBannerProps> = ({ onClose }) => {
  const [isVisible, setIsVisible] = useState(true);
  const [isScrolling, setIsScrolling] = useState(false);
  const [bannerType, setBannerType] = useState<'A' | 'B'>('A'); 
  const articleIds = [
    '8c31-r-c2', '5teta56ik08', 'a5q4r1cuyztq', '91aypylhsnp', 'rna7t_tn0', 'v1nyl6vr4l32',
    'qj64ls5nc', '94rc-epdqe', 'xu2d16nuw', 'nv63-ckx7n', '7t6la6-sm4a', 'whgly_sax67k',
    'flplvw-jb', 'yjhbblmv_7i', 'jetbhefvqf4', '3qmk4buyky', 'bjlfnr-8t1fx', 'kl-8rlvv5',
    'kztbtiqc6vr', 'op0ua0tsn1', 'oh4--clha', 'alsnyte1pr', 'eg_jwcf4llm', 'j5i25kfrj',
    'i12x202gyl', 'dm83n_pcs5w', 's-7sq0y_tgaz', '8vjdfprbk', 'wdodm02sacr6', '20oyjoserivv',
    '44f5zcr85cs', '9fhs3uina7c', '9mwdbjydytlc', '1e25p0nek', 'onk9l-b1uhl', 'vcslqj05f9'
  ];

  useEffect(() => {
    const currentPageUrl = window.location.href;
    if (articleIds.some(id => currentPageUrl.includes(`articles/${id}`))) {
      setBannerType('B');
    } else {
      setBannerType('A');
    }

    
    const isBannerClosed = localStorage.getItem('popupBannerClosed');
    if (isBannerClosed === 'true') {
      setIsVisible(false); 
    }

    
    const lastClosedTime = localStorage.getItem('popupClosedTime');
    if (lastClosedTime) {
      const currentTime = new Date().getTime();
      const threeHours = 3* 60* 60* 1000; 
      const timeDifference = currentTime - parseInt(lastClosedTime, 10);
      if (timeDifference < threeHours) {
        onClose();
        setIsVisible(false); 
      }
      else{
        setIsVisible(true); 
      }
    }
  }, []);

  useEffect(() => {
    let prevScrollY = window.pageYOffset;

    const handleScroll = () => {
      const currentScrollY = window.pageYOffset;
      const isScrollingUp = currentScrollY < prevScrollY;

      setIsScrolling(true); 
      setTimeout(() => setIsScrolling(false), 500); 

      prevScrollY = currentScrollY;
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleImageClick = () => {
    window.location.href = 'https://iot.dxhub.co.jp/lp';
  };

  const handleClose = () => {
    setIsVisible(false);
    onClose();
    localStorage.setItem('popupBannerClosed', 'true');
    const currentTime = new Date().getTime();
    localStorage.setItem('popupClosedTime', currentTime.toString()); 
  };

  const bannerSrc = `/Banner ${bannerType}.png`;

  return (
    <div className={`popup-banner ${isVisible ? '' : 'fade-out'} ${isScrolling ? 'fade-out' : ''}`}>
      <a href="https://iot.dxhub.co.jp/lp" onClick={handleImageClick}>
        <img src={bannerSrc} alt="Banner" />
      </a>
      <button className="close-button" onClick={handleClose}>
        <IoMdClose />
      </button>
    </div>
  );
}

export default PopupBanner;
