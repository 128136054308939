import { FC } from 'react'
import Link from 'next/link'

type Props = {}

const Component: FC<Props> = ({}) => {
  return (
    <>
      <div className="w-full h-60 md:h-56 p-5 xl:w-[250px] xl:h-[280px] xl:rounded-[4px] bg-white flex flex-col items-center md:flex-row md:items-center md:justify-around md:mt-4 xl:flex-col xl:mt-0 xl:items-center xl:justify-around">
        <div className="xs:w-4/5 text-center md:text-left md:w-1/3 xl:text-center xl:w-[188px]">
          <p className="text-primary-900 font-bold text-xl">IoT/M2M向けSIMについてお問い合わせ</p>
          <p className="text-primary-900 mt-4">IoT/M2M向けSIMについてご興味がある方はお問い合わせください。</p>
        </div>

        <Link href="/lp" prefetch={false}>
          <a className="bg-accent-500 my-4 text-xl leading-none text-primary-900 py-5 px-6 rounded-[4px] font-semibold">
            問い合わせする
          </a>
        </Link>
      </div>
    </>
  )
}

export default Component
