import { FC } from 'react'

type Props = {
  title: string
}

const Component: FC<Props> = ({ title }) => {
  return (
    <div className="flex gap-2 w-[300px] xs:w-full items-center relative bottom-[6px]">
      <p className="text-xs whitespace-nowrap">{title}</p>
      <div className="w-full">
        <div className="border-b border-1 border-divided before" />
      </div>
    </div>
  )
}

export default Component
