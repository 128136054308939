import type { NextPage, GetStaticProps } from 'next'
import Container, { Props } from '@/containers/Top'
import { mediaClient } from 'src/apiMiddleware'
import { Article } from '@/api/types'
import Head from 'next/head'
import { contentModify } from '@/utils'
import { ArticleDetailBase } from '@/route'

const LIMIT = 1000
const rec = 'おすすめ'
const iot = 'IoT'
const news = 'ニュース'

export const getStaticProps: GetStaticProps<Props, {}> = async () => {
  const tags = await mediaClient.tags.$get({ query: { limit: LIMIT } })

  const tagRec = tags.contents.find((t) => t.name === rec)
  const tagIot = tags.contents.find((t) => t.name === iot)
  const tagNews = tags.contents.find((t) => t.name === news)
  const latestPromise = mediaClient.articles.$get({ query: { limit: 6, offset: 0, orders: '-revisedAt' } })
  const recPromise = mediaClient.articles.$get({
    query: { limit: LIMIT, offset: 0, filters: `tags[contains]${tagRec?.id}` },
  })
  // filtered news articles
  const newsPromise = mediaClient.articles.$get({
    query: { limit: LIMIT, offset: 0, filters: `tags[contains]${tagNews?.id}` },
  });
  const categoriesPromise = mediaClient.categories.$get({ query: { limit: LIMIT, offset: 0 } })
  const bannerPromise = mediaClient.banner.$get()

  const whitepaperPromise = mediaClient.whitepapers.$get({ query: { limit: 3, offset: 0, orders: '-revisedAt' } })

  const [latestArticles, recommendations, newsRecommendations, categories, banner, whitepapers] = await Promise.all([
    latestPromise,
    recPromise,
    newsPromise,
    categoriesPromise,
    bannerPromise,
    whitepaperPromise,
  ])
  const recommendationsIoT: Article[] = []
  const recommendationsOthers: Article[] = []
  const recommendationsNews: Article[] = []
  recommendations.contents.map((r) => {
    const recIoT = r.tags.find((t) => t.id === tagIot?.id);
    if (recIoT !== undefined) {
      recommendationsIoT.push(r);
    } else {
      recommendationsOthers.push(r);
    }

  });
  // News article recommendations
  newsRecommendations.contents.map((r) => {
    const recNews = r.tags.find((t) => t.id === tagNews?.id);
    if (recNews !== undefined) {
      recommendationsNews.push(r);
    } else {
      recommendationsOthers.push(r);
    }
  });

  return {
    props: {
      topArticles: contentModify(latestArticles.contents, ArticleDetailBase),
      recommendationsIoT: contentModify(recommendationsIoT.slice(0, 6), ArticleDetailBase),
      recommendationsNews: contentModify(recommendationsNews.slice(0, 3), ArticleDetailBase),
      recommendationsOthers: contentModify(recommendationsOthers.slice(0, 3), ArticleDetailBase),
      latestArticles: contentModify(latestArticles.contents, ArticleDetailBase),
      tags: tags.contents,
      categories: categories.contents,
      banner,
      whitepapers: whitepapers.contents,
    },
  }
}

const Home: NextPage<Props> = (props) => {
  return (
    <>
      <Head>
        <title key="title">トップ｜IoTBiz｜DXHUB株式会社</title>
        <meta property="og:title" content="トップ｜IoTBiz｜DXHUB株式会社" key="ot" />
      </Head>
      <Container {...props} />
    </>
  )
}

export default Home
