import { FC } from 'react'
import Link from 'next/link'

type Props = {
  title: string
  url: string
}

const Component: FC<Props> = ({ title, url }) => {
  return (
    <Link href={url} prefetch={false}>
      <a className="bg-primary-800 my-6 text-white py-4 px-5 rounded-[4px] font-semibold leading-none">{title}</a>
    </Link>
  )
}

export default Component
