import React, { FC, useEffect, useState } from 'react';
import PopupBanner from '../components/PopupBanner';
import Card, { Props as CardProps } from '@/components/CardMain';
import CardWP from 'src/components/WhitepaperCard';
import CardList from '@/components/module/CardList';
import { ArticleTag, ArticleCategory, SideBanner, Whitepaper } from 'src/api/types';
import MailMagazin from '@/components/MailMagazin';
import LPNav from '@/components/LPNav';
import Category from '@/components/Category';
import SideTag from '@/components/SideTag';
import SideBarDivider from '@/components/SideBarDivider';
import FlexDivider from '@/components/FlexDivider';
import { ArticlePageBase, WhitepaperDetailBase } from '@/route';
import LinkButton from '@/components/Button';
import 'keen-slider/keen-slider.min.css';
import { useKeenSlider } from 'keen-slider/react';
import CardTop, { Props as CardTopProps } from '@/components/CardTop';
import Banner from '@/components/Banner';

export type Props = {
  topArticles: CardTopProps[];
  recommendationsIoT: CardProps[];
  recommendationsNews: CardProps[];
  recommendationsOthers: CardProps[];
  latestArticles: CardProps[];
  tags: ArticleTag[];
  categories: ArticleCategory[];
  banner: SideBanner;
  whitepapers: Whitepaper[];
};

const Container: FC<Props> = ({
  topArticles: tops,
  recommendationsIoT: rIoT,
  recommendationsNews: rNews,
  recommendationsOthers: rOthers,
  latestArticles: latests,
  tags,
  categories,
  banner,
  whitepapers,
}) => {
  const wps = whitepapers.map((w) => {
    return { title: w.title, eyeCatchingUrl: w.eyeCatching.url, url: `${WhitepaperDetailBase}/${w.id}` };
  });

  const [showPopupBanner, setShowPopupBanner] = useState(true);

  const handleClosePopupBanner = () => {
    setShowPopupBanner(false);
  };

  const [refCallback] = useKeenSlider(
    {
      loop: true,
      breakpoints: {
        '(min-width: 400px)': {
          slides: { perView: 1, spacing: 5 },
        },
        '(min-width: 768px)': {
          slides: { perView: 2, spacing: 10 },
        },
        '(min-width: 1280px)': {
          slides: { perView: 3, spacing: 10 },
        },
        '(min-width: 1440px)': {
          slides: { perView: 3, spacing: 10 },
        },
      },
      slides: {
        perView: 1,
        spacing: 5,
      },
    },
    [
      (slider) => {
        let timeout: ReturnType<typeof setTimeout>;
        let mouseOver = false;
        function clearNextTimeout() {
          clearTimeout(timeout);
        }
        function nextTimeout() {
          clearTimeout(timeout);
          if (mouseOver) return;
          timeout = setTimeout(() => {
            slider.next();
          }, 3000);
        }
        slider.on('created', () => {
          slider.container.addEventListener('mouseover', () => {
            mouseOver = true;
            clearNextTimeout();
          });
          slider.container.addEventListener('mouseout', () => {
            mouseOver = false;
            nextTimeout();
          });
          nextTimeout();
        });
        slider.on('dragStarted', clearNextTimeout);
        slider.on('animationEnded', nextTimeout);
        slider.on('updated', nextTimeout);
      },
    ]
  );

  useEffect(() => {
    const lazyImages = [].slice.call(document.querySelectorAll('img'));
    const lazyImageObserver = new IntersectionObserver(function (entries, _observer) {
      entries.forEach(function (entry) {
        if (entry.isIntersecting) {
          const lazyImage = entry.target as HTMLImageElement;
          lazyImage.loading = 'eager';
          lazyImageObserver.unobserve(lazyImage);
        }
      });
    });

    lazyImages.forEach(function (lazyImage) {
      lazyImageObserver.observe(lazyImage);
    });
  }, []);

  return (
    <>
      <main className="flex flex-col items-center">
        <div ref={refCallback} className="keen-slider">
          {tops.map((a) => {
            return (
              <div key={'top-' + a.id} className="keen-slider__slide">
                <CardTop {...a} />
              </div>
            );
          })}
        </div>

        <div className="xs:w-[342px] md:min-w-max">
          <div className="my-5 w-full md:w-full md:text-left">
            <h2 className="w-full text-xl md:text-2xl font-semibold text-secondary-700">おすすめ記事</h2>
          </div>
          <div className="flex justify-center gap-6">
            <div className="card-container flex flex-col items-center">
              {/* IoT */}
              <FlexDivider title="IoT" />
              <CardList>
                {rIoT.map((a, idx) => {
                  return <Card key={`iot-${a.title}-${idx}`} {...a} tagPrefix="iot" />;
                })}
              </CardList>

              <div className="mt-8" />

              {/* News */}
              <FlexDivider title="ニュース" />
              <CardList>
                {rNews.map((a, idx) => {
                  return <Card key={`other-${a.title}-${idx}`} {...a} tagPrefix="other" />;
                })}
              </CardList>

              <div className="mt-8" />

              {/* Others */}
              <FlexDivider title="その他" />
              <CardList>
                {rOthers.map((a, idx) => {
                  return <Card key={`other-${a.title}-${idx}`} {...a} tagPrefix="other" />;
                })}
              </CardList>
            </div>
            <div className="hidden xl:sidebar">
              <div style={{ marginBottom: '20px' }}>
                <Banner {...banner} />
              </div>
              <MailMagazin />
              <SideBarDivider title="カテゴリー" />
              <Category categories={categories} />
              <SideBarDivider title="キーワードから探す" />
              <SideTag tags={tags} />
            </div>
          </div>
        </div>
        <section className="flex flex-col xl:flex-row xl:justify-center xl:items-center gap-6 my-8 bg-primary-900 w-full xl:h-[520px]">
          <div className="lpcv-container flex flex-col justify-center xl:flex-row xl:justify-between items-center">
            <img src="/lp-nav.svg" alt="iotネットワークとSIM" width="500" height="500" loading="lazy" />
            <div className="text-white px-5 text-center xl:px-0 xl:text-right text-xl flex flex-col gap-4">
              <p className="font-bold text-3xl">1枚からすぐに始められるIoT/M2M向け法人SIM</p>
              <div>
                <p>通信量・対応エリア・運用費を</p>
                <p>利用シーンに合わせてカスタマイズ</p>
              </div>
              <ul className="list-inside list-disc flex flex-col gap-4">
                <li>１枚から利用可能</li>
                <li>契約期間縛りなし</li>
                <li>最短即日発送</li>
              </ul>
            </div>
          </div>
          <div className="lpcv-sidebar">
            <LPNav />
          </div>
        </section>
        <div className="xs:w-[342px] md:min-w-max">
          <div className="mx-2 xs:mx-0 my-4 xl:w-[900px] md:text-left">
            <SideBarDivider h2={true} fontSize="text-2xl" title="新規記事" />
          </div>
          <div className="flex justify-center gap-4">
            <div className="card-container flex flex-col items-center">
              <CardList>
                {latests.map((a, idx) => {
                  return <Card key={`latest-${a.title}-${idx}`} tagPrefix="latest" {...a} />;
                })}
              </CardList>

              <LinkButton title="もっとみる" url={ArticlePageBase + '/1'} />

              <SideBarDivider h2={true} fontSize="text-2xl" title="お役立ち資料" />

              <div className="w-full whitepaper-card-list my-4">
                {wps.map((w, idx) => {
                  return <CardWP key={`${w.title}-${idx}`} title={w.title} eyeCatchingUrl={w.eyeCatchingUrl} url={w.url} />;
                })}
              </div>

              <div className="sidebar xl:hidden">
                <MailMagazin />
                <SideBarDivider title="カテゴリー" />
                <Category categories={categories} />
                <Banner {...banner} />
                <SideBarDivider title="キーワードから探す" />
                <SideTag tags={tags} />
              </div>
            </div>
            <div className="hidden xl:sidebar"></div>
          </div>
        </div>
      </main>
      {showPopupBanner && <PopupBanner onClose={handleClosePopupBanner} />}
    </>
  );
};

export default Container;
