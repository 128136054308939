import { FC, useRef } from 'react'
import Link from 'next/link'
import Tag from './Tag'
import { IconClock, IconRefresh } from '@tabler/icons'
import Image from '@/components/Image'
import { ArticleTag } from 'src/api/types'

export type Props = {
  id: string
  eyeCatching: {
    url: string
  },
  title: string
  description: string
  revisedAt?: string
  readTime: string
  tags: ArticleTag[]
  url: string
}

const Component: FC<Props> = ({ title, eyeCatching,  revisedAt, readTime, url, tags }) => {
  const ref = useRef<HTMLAnchorElement | null>(null)
  const onClickCard = () => {
    if (ref.current != null) {
      ref.current.click()
    }
  }

  const size = 'w=400&h=225'

  return (
    <>
      <div
        onClick={onClickCard}
        className="sm:min-w-[400px] sm:min-h-[307px]
        hover:shadow-md hover:opacity-90"
      >
        <div className="aspect-video w-full">
          <Image url={eyeCatching.url} size={size} />
        </div>

        <Link href={url} prefetch={false}>
          <a
            ref={ref}
            className="line-clamp-2 h-14 md:h-11 px-2 text-xl md:text-base mt-2 font-semibold hover:text-primary-500 inline-block"
          >
            {title}
          </a>
        </Link>

        <div className="flex mt-2 justify-between items-center px-3 gap-2">
          <section className="flex gap-2 overflow-hidden">
            {tags.map((tag, idx) => (
              <Tag key={tag.id + "-" + idx} name={tag.name} />
            ))}
          </section>

          <div className="flex gap-2 text-gray-600">
            <div className="flex gap-1 items-center whitespace-nowrap">
              <IconRefresh width="12" height="12" />
              <p>{revisedAt}</p>
            </div>
            <div className="flex gap-1 items-center">
              <IconClock width="12" height="12" />
              <p>{readTime}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Component
